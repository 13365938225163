import './App.css';
import CvList from './components/CvList';

function App() {
  return (
    <div>
      <CvList/>
    </div>
  );
}

export default App;
