
import './../css/CvList.css';
import workJson from './../work.json';

function CvList() {
  return (
    <div className="cv-list-wrapper">
      <div className="cv-header">
        <h1>Open Index</h1>
        <a className='github-link' rel="noreferrer" target="_blank" href="https://www.github.com/jonathanporras">GitHub</a>
      </div>
      <p>Open Index is a design-led web development studio with a focus on the fields of art, fashion and culture.</p>
      <a href='mailto:porrasjon@gmail.com'>jon@openindex.studio</a>
      <div className='work-list'>
        {workJson.work.map((work) => {
          return (
            <div className="work-item">
              <p>{work.year}</p>
              <p>{work.client}</p>
              <a rel="noreferrer" target="_blank" href={`https://www.${work.link}`}>{work.link}</a>
              <span>with <a rel="noreferrer" target="_blank" href={`${work.designerLink}`}>{work.designer}</a></span>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default CvList;
